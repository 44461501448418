
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import LoyaltyProgramsTable from '@/components/loyaltyPrograms/LoyaltyProgramsTable.vue';
import { namespace } from 'vuex-class';
import { Loyalty } from '@/interfaces/models/Loyalty';

const loyalty = namespace('loyalty');

@Component({
  components: { LoyaltyProgramsTable, VWrapper },
})
export default class LoyaltyPrograms extends Vue {
  @loyalty.Action('fetch') public getLoyaltyPrograms!: any;
  @loyalty.Action('destroy') public deleteLoyalty!: any;
  @loyalty.State('items') public loyaltyPrograms!: Loyalty[];

  public async mounted() {
    this.$startLoading('loyaltyPrograms');
    await this.getLoyaltyPrograms();
    this.$stopAllLoading();
  }

  public async destroy(loyalty: Loyalty) {
    if (!loyalty?._id) return;
    this.$startLoading('loyalty');
    await this.deleteLoyalty({ id: loyalty._id });
    this.$stopLoading('loyalty');
  }
}
