
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Company as CompanyModel } from '@/interfaces/models/Company';
import CompanyTable from '@/components/company/CompanyTable.vue';
import { Venue } from '@/interfaces/models/Venue';
import { Page } from '@/interfaces/api/Page';

const app = namespace('app');
const company = namespace('company');

@Component({
  components: { CompanyTable, VWrapper },
})
export default class Company extends Vue {
  @app.State('venues') public venues!: Venue[];
  @company.Action('fetch') public getCompanies!: any;
  @company.Action('destroy') public deleteCompany!: any;
  @company.State('items') public companies!: CompanyModel[];
  @company.State((state) => state.pagination.total) public total!: number;
  @company.Action('setFilter') public setFilter!: any;

  public async mounted() {
    this.$startLoading('company');
    this.setFilter({ page: 1, limit: 10 });
    await this.getCompanies();
    this.$stopAllLoading();
  }

  public async onPageChange(page: Page) {
    if (page) {
      this.$startLoading('company');
      this.setFilter({ ...page });
      await this.getCompanies();
      this.$stopAllLoading();
    }
  }

  public async destroy(company: CompanyModel) {
    if (!company?._id) return;
    this.$startLoading('company');
    await this.deleteCompany({ id: company._id });
    this.$stopLoading('company');
  }
}
