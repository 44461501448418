
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import headers from '@/components/loyaltyPrograms/headers';
import { Loyalty } from '@/interfaces/models/Loyalty';
import { namespace } from 'vuex-class';

const loyalty = namespace('loyalty');

@Component({
  components: { VTable },
})
export default class LoyaltyProgramsTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Loyalty[];

  @loyalty.Action('show') public show!: any;
  @loyalty.Action('setActiveLoyalty') public setActive!: any;

  get headers() {
    return headers;
  }

  public edit(item: Loyalty) {
    this.setActive(item);
    this.$router.push({ name: 'loyaltyPrograms.edit', params: { id: item._id } });
  }
}
