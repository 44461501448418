export default [
  {
    text: 'loyaltyPrograms.table.type',
    value: 'type',
  },
  {
    text: 'loyaltyPrograms.table.customerGroup',
    value: 'customerGroup',
  },
  {
    text: 'loyaltyPrograms.table.venue',
    value: 'venue',
  },
  {
    text: 'loyaltyPrograms.table.shopUuid',
    value: 'piggyClientCredentials.shopUuid',
    type: 'slot',
    virtual: true,
  },
];
