
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import headers from '@/components/subscriptionCategories/headers';
import adminHeaders from '@/components/subscriptionCategories/adminHeaders';
import { namespace } from 'vuex-class';
import { Membership } from '@/interfaces/models/Membership';

const membership = namespace('membership');

@Component({
  components: { VTable },
})
export default class MembershipTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Membership[];
  @Prop({ type: Number, required: true }) public total!: number;

  @membership.Action('setActiveMembership') protected setActive!: any;

  get headers() {
    if (this.$isAdmin()) {
      return adminHeaders;
    }

    return headers;
  }

  public edit(item: Membership) {
    this.setActive(item);
    this.$router.push({ name: 'membership.edit', params: { id: item._id } }).catch();
  }
}
