
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import MembershipTable from '@/components/membership/MembershipTable.vue';
import { Membership as MembershipModal } from '@/interfaces/models/Membership';

const membership = namespace('membership');

@Component({
  components: { MembershipTable, VWrapper },
})
export default class Membership extends Vue {
  @membership.State('memberships') public items!: MembershipModal[];

  @membership.Action('fetchMemberships') protected getMemberships!: any;
  @membership.Action('destroyMembership') protected deleteMembership!: any;

  public async destroy(membership: MembershipModal) {
    await this.deleteMembership({ _id: membership._id });
  }

  public async mounted() {
    this.$startLoading('memberships');
    await this.getMemberships();
    this.$stopAllLoading();
  }
}
