
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import headers from './headers';
import { Company } from '@/interfaces/models/Company';
import { Venue } from '@/interfaces/models/Venue';

@Component({
  components: { VTable },
})
export default class CompanyTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Company[];
  @Prop({ type: Array, required: true }) private venues!: Venue[];
  @Prop({ type: Number, required: true }) public total!: number;

  get headers() {
    return headers;
  }

  getVenueById(id: string): string {
    const venue = this.findVenueById(id);
    return venue ? venue.name : id;
  }

  private findVenueById(id: string): Venue | undefined {
    return this.venues.find((venue: Venue) => venue._id === id);
  }

  public edit(item: Company) {
    this.$router.push({ name: 'company.edit', params: { id: item._id } });
  }
}
