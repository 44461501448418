export default [
  {
    text: 'company.table.venue',
    value: 'venue',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'company.table.name',
    value: 'name',
    default: true,
    align: 'left',
  },
  {
    text: 'company.table.companyCode',
    value: 'companyCode',
    default: true,
    align: 'left',
  },
  {
    text: 'company.table.email',
    value: 'email',
    default: true,
    align: 'left',
  },
  {
    text: 'company.table.phoneNumber',
    value: 'phoneNumber',
    default: true,
    align: 'left',
  },
  {
    text: 'company.table.createdAt',
    value: 'createdAt',
    default: true,
    type: 'slot',
    align: 'left',
  },
];
